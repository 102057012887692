import { toggleMsgLoader, renderCustomComponent, toggleInputDisabled } from 'react-chat-widget'
import csrfToken from '../../src/csrf_token'
import InvalidQuery from './InvalidQuery'

export const performRequest = async (message, renderAnswerMessage, updateMessagesHistory, invalidQueryMessage) => {
  try {
    const response = await fetch(`/api/gcp/search?query=${encodeURIComponent(message)}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'X-CSRF-Token': csrfToken()
      }
    })

    if (!response.ok) {
      throw new Error(response)
    }

    const responseJson = await response.json()

    if (responseJson.answer.trim() === "NOT APPLICABLE") throw new Error('Not applicable query.')

    renderAnswerMessage(responseJson)
    updateMessagesHistory(responseJson.answer, responseJson.products, 'promptAnswer')
  } catch (error) {
    console.error(error)

    renderCustomComponent(InvalidQuery, { message: invalidQueryMessage })
    updateMessagesHistory(invalidQueryMessage, null, 'promptCustomError')
  } finally {
    toggleMsgLoader()
    toggleInputDisabled()
  }
}

export const getChatVisibilityValue = () => {
  const itemStr = localStorage.getItem('isChatVisibleItem')

  if (!itemStr) return null

  const item = JSON.parse(itemStr)
  const now = new Date()

  if (now.getTime() > item.expiry) {
    localStorage.removeItem('isChatVisibleItem')

    return null
  }

  return item.value
}

export const updateChatVisibilityItem = (value, ttl) => {
  const now = new Date()

  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  }

  localStorage.setItem('isChatVisibleItem', JSON.stringify(item))
}
