import React, { useState, useEffect } from 'react'
import csrfToken from '../src/csrf_token'

const RECOMMENDATIONS_PATH = '/api/v1/fr_products'

const FreshRelevanceRecommendations = ({ slot, partial, fallbackPartial, fallbackTimeout }) => {
  const [recommendationsHtml, setRecommendationsHtml] = useState(null)
  const parsedTimeout = isNaN(parseInt(fallbackTimeout)) ? null : parseInt(fallbackTimeout)

  const fetchRecommendations = async () => {
    try {
      const params = new URLSearchParams({ slot: slot, partial: partial })
      const fullPath = `${RECOMMENDATIONS_PATH}?${params}`
      const opts = {
        method: 'GET',
        headers: {
          'X-CSRF-Token': csrfToken(),
          'Content-Type': 'text/html'
        }
      }
      if (parsedTimeout) opts.signal = AbortSignal.timeout(parsedTimeout)

      const response = await fetch(fullPath, opts)

      if (!response.ok) {
        setRecommendationsHtml(() => fallbackPartial)
      } else {
        const data = await response.text()
        setRecommendationsHtml(() => data)
      }
    } catch (_error) {
      setRecommendationsHtml(() => fallbackPartial)
    }
  }

  useEffect(() => fetchRecommendations(), [])

  useEffect(() => {
    if (ReactRailsUJS) ReactRailsUJS.mountComponents()
  },[recommendationsHtml])

  return <>
    {recommendationsHtml && <div dangerouslySetInnerHTML={{ __html: recommendationsHtml }}></div>}
  </>
}

export default FreshRelevanceRecommendations
