import React from 'react'
import PropTypes from 'prop-types'
import csrfToken from '../../src/csrf_token'
import CartProductItem from './CartProductItem'
import CartGiftItem from './CartGiftItem'
import CartRecommendedItem from './CartRecommendedItem'

class CartContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      cartItems: this.props.cartItems,
      modalOpen: false,
      recommendedProducts: []
    }

    this.refreshCart = this.refreshCart.bind(this)
  }

  componentDidMount() {
    Mess.subscribe('refresh_wishlist', this.refreshCart)
    const { recommendationsService } = this.props

    if (typeof nostojs !== 'undefined' && recommendationsService === 'nosto') {
      nostojs(api => {
        api.loadRecommendations()

        .then(result => {
          const recommendations = result.recommendations

          this.setProducts(recommendations)
        })
        .catch(error =>  {
          console.log(error, 'error')
        })
      })
    } else if (recommendationsService === 'fresh_relevance') {
      this.fetchFreshRelevanceRecommendations()
    }
  }

  refreshCart(data) {
    this.setState({
      cartItems: data.cart.cartItems
    })
  }

  setProducts(recommendationsList) {
    const productsIdsUrlPairs = this.parseProductIdsUrlPairs(recommendationsList)

    if (productsIdsUrlPairs.length > 0) {
      this.fetchNostoProducts(productsIdsUrlPairs)
    } else {
      this.setState({ recommendedProducts: [] })
    }
  }

  parseProductIdsUrlPairs(recommendationsList) {
    const nostoRecommendationSlotId = this.props.recommendationSlotId

    const nostoData = recommendationsList[nostoRecommendationSlotId]

    if (nostoData !== undefined) {
      return JSON.parse(nostoData).productIdUrlPairs
    } else {
      return []
    }
  }

  fetchNostoProducts(productsIdsUrlPairs) {
    $.ajax({
      url: '/api/v1/products',
      dataType: 'json',
      method: 'get',
      headers: {
        "X-CSRF-Token": csrfToken()
      },
      data: {
        idsUrlPairs: productsIdsUrlPairs,
        nosto_slot_id: this.props.recommendationSlotId
      },

      success: function(responseProducts) {
        this.setState({ recommendedProducts: responseProducts })
      }.bind(this),

      error: function(xhr, status, err) {
        this.setState({ recommendedProducts: [] })
      }.bind(this)
    })
  }

  async fetchFreshRelevanceRecommendations() {
    try {
      const { recommendationSlotId } = this.props
      const params = new URLSearchParams({ slot: recommendationSlotId })
      const fullPath = `/api/v1/fr_products?${params}`
      const opts = {
        method: 'GET',
        headers: {
          'X-CSRF-Token': csrfToken(),
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }

      const response = await fetch(fullPath, opts)

      if (!response.ok) {
        this.setState({ recommendedProducts: [] })
      } else {
        const data = await response.json()
        this.setState({ recommendedProducts: data })
      }
    } catch (_error) {
      this.setState({ recommendedProducts: [] })
    }
  }


  render() {
    const userName = this.props.userName
    const productsCount = this.state.cartItems.length

    return (
      <div className="CartContainer">
        <div className="CartContainer-title">
          {userName ? `Hey ${userName}, here's your Cart` : 'Your Cart'}
        </div>

        <div className="CartContainer-itemsCount">
          {productsCount} {productsCount === 1 ? 'Item' : 'Items'}
        </div>

        <div className="CartContainer-listHeader">
          <div className="CartContainer-listHeader-columnProduct">Products</div>
          <div className="CartContainer-listHeader-columnPrice">Price</div>
          <a className="CartContainer-listHeader-columnClear" href="/orders/all/remove_items" data-method="delete">Clear cart</a>
        </div>

        <div className="CartContainer-itemsList">
          {
            this.state.cartItems.map(item => {
              return <CartProductItem key={item.order_item_id} item={item} fallbackImageSrc={this.props.fallbackImageSource} />
            })
          }
          {(this.props.gifts.length > 0) && (
            <CartGiftItem fallbackImageSrc={this.props.fallbackImageSource}
                          gifts={this.props.gifts}
                          selectedGift={this.props.selectedGift}
                          totalCash={this.props.totalCash} />
          )}

          <CartRecommendedItem
            fallbackImageSrc={this.props.fallbackImageSource}
            recommendedProducts={this.state.recommendedProducts}
            alreadyInCartProducts={this.state.cartItems}
            fallbackProducts={this.props.fallbackProducts}
            recommendationsService={this.props.recommendationsService}
          />
        </div>

        <div className="CartContainer-secureButton">
          <a className="CartContainer-secureButton-button button-plain" href="/checkout"><p className='CartContainer-secureButton-lock'></p>Secure Checkout</a>
        </div>
      </div>
    )
  }
}

CartContainer.propTypes = {
  orderId: PropTypes.number.isRequired,
  userName: PropTypes.string,
  cartItems: PropTypes.array.isRequired,
  fallbackImageSource: PropTypes.string.isRequired,
  gifts: PropTypes.array.isRequired,
  selectedGift: PropTypes.object,
  recommendationSlotId: PropTypes.string.isRequired
}

export default CartContainer
